<template>
  <div>
    <ag-grid-vue
      style="height: 800px"
      class="ag-theme-alpine"
      ref="table"
      :pagination="true"
      :paginationPageSize="300"
      :rowData="rowData"
      :columnDefs="columnDefs"
      :localeText="localText"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";
import gridLocale from "@/components/local-services/sub-components/agGridLocale.uk.js";
export default {
  props: ["rowData"],
  components: {
    AgGridVue,
  },
  data() {
    return {
      columnDefs: null,
      localText: gridLocale,
    };
  },
  methods: {},
  beforeMount() {
    this.columnDefs = [
      {
        field: "id",
         headerName: "№",
        width: 80,
        sortable: true,
        sort: "asc",
        type: "numericColumn",
        comparator: function(valueA, valueB) {
          return Number(valueA) == Number(valueB)
            ? 0
            : Number(valueA) > Number(valueB)
            ? 1
            : -1;
        },
      },
      {
        headerName: "Прізвище",
        field: "last_name",
        resizable: true,
        sortable: true,
        filter: "agTextColumnFilter",
      },
      { headerName: "Імʼя", width: 120, field: "first_name", resizable: true },

      {
        headerName: "По-батькові",
        width: 180,
        field: "patronymic",
        resizable: true,
      },
      {
        field: "f_year",
        headerName: "Рік внесення",
        width: 130,
        resizable: true,
        sortable: true,
      },
      { field: "note", headerName: "Опис", width: 400, resizable: true },
    ];
  },
};
</script>

<style lang="scss" scoped></style>
