<template>
  <div class="floating-bar">
    <div class="card d-flex justify-content-between">
      <div
        @click="openSidebar"
        class="d-lg-none floating-bar__menu-toggle d-flex align-items-center"
      >
        <icon variant="menu" />
        <div class="notifications" v-if="notificaions > 0"></div>
      </div>
      <div class="floating-bar__title mr-auto align-items-center">
        <span>
          {{ title }}
        </span>

        <div class="test-badge d-none">Тестовий режим</div>
      </div>
      <user />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import user from "@/layout/floating-bar-components/floating-bar-user.vue";
export default {
  components: {
    user,
  },
  computed: {
    ...mapGetters({
      notificaions: "getNotificationsCount",
    }),
    title() {
      return this.$route.meta.title;
    },
  },
  methods: {
    openSidebar() {
      this.$store.dispatch("toggleSidebar", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.floating-bar {
  display: flex;
  align-items: flex-end;
  position: fixed;
  right: 0px;
  top: 0px;
  width: calc(100vw - var(--sidebar-width));
  padding: 12px 20px 0px;
  box-sizing: border-box;
  z-index: 1000;
  border-radius: 6px;
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 97.3%, 0.95) 44%,
    hsla(0, 0%, 97.3%, 0.46) 73%,
    hsla(0, 0%, 100%, 0)
  );
  @media (max-width: 991px) {
    width: 100vw;
  }
  .test-badge {
    color: #f00;
    padding: 4px 8px;
    font-size: 13px;
    margin-left: 20px;
    background-color: rgba(#f00, 0.1);
    border-radius: 6px;
    font-weight: 500;
    line-height: 1;
  }
  .card {
    min-width: 100%;

    display: flex;
    align-items: center;
    padding: 5px 12px;
    height: 56px;
    flex-direction: row;
    justify-content: space-between;
  }
  //   box-shadow: 0px 0px 18px 0px rgba(#000,0.1);
  &__menu-toggle {
    cursor: pointer;
    margin-right: 10px;
    position: relative;
    .notifications {
      width: 12px;
      height: 12px;
      background: #f00;
      border-radius: 50%;
      position: absolute;
      top: -6px;
      right: -6px;
    }
    svg {
      height: 20px;
      width: 20px;
      fill: #104d82;
    }
  }
  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    display: flex;
    @media (max-width: 991px) {
      display: none;
    }
  }
}
</style>
