<template>
  <div class="single-ticket">
    <b-overlay
      :show="isLoading"
      :class="{ 'overlay-loading': isLoading }"
      variant="white"
      rounded="sm"
      spinner-variant="warning"
    >
      <div class="back-button">
        <router-link :to="{ name: 'tickets-all' }">
          <icon
            variant="arrow-right"
            color="#3683fb"
            style="transform: scaleX(-1)"
          />
          Всі запити
        </router-link>
      </div>
      <template v-if="ticket">
        <h1 clss="mt-0">{{ getType(ticket.type) }} #{{ ticket.id }}</h1>
        <p>
          Створено
          <strong v-if="ticket.created_at">
            {{ ticket.created_at.seconds | moment("DD MMMM YYYY  о  H:mm") }}
          </strong>
        </p>
        <b-card class="mb-4">
          <div class="row">
            <div class="col-12 col-md-6 mb-4">
              <h6>Поточний статус</h6>
              <strong>
                <statusBadge :status="ticket.status" />
              </strong>
            </div>
            <div class="col-12 col-md-6 mb-4">
              <h6>Відділ / Підрозділ</h6>
              <strong>
                {{ getDepartment(ticket.department).name }}
              </strong>
            </div>
            <div class="col-12 col-md-6 mb-4 files">
              <h6>Файли</h6>
              <template v-if="ticket.files.length">
                <div v-for="f in ticket.files" :key="f.id">
                  <a :href="f.file_url" target="_blank">
                    {{ f.orig_path }}
                  </a>
                </div>
              </template>
              <template v-else> -- </template>
            </div>
            <div class="col-12 mb-2">
              <h6>Текст запиту</h6>
              {{ ticket.text }}
            </div>
          </div>
        </b-card>
      </template>
      <h3>Відповіді та історія змін</h3>
      <template v-if="events.length">
        <ticketEvent v-for="e in events" :key="e.id" :event="e" />
      </template>
      <template v-if="!events.length && !isLoading">
        <b-alert show variant="primary"> Нічого не знайдено </b-alert>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ticketEvent from "@/components/single-ticket/ticket-event-item.vue";
import statusBadge from "@/components/ticket-status-badge";
import { mapGetters } from "vuex";
export default {
  components: { statusBadge, ticketEvent },
  data() {
    return {
      isLoading: false,
      ticket: null,
      events: [],
    };
  },
  computed: {
    ...mapGetters({
      datasets: "getDatasets",
      user: "getUser",
    }),
    statuses() {
      return this.datasets.statuses;
    },
    ticket_types() {
      return this.datasets.ticket_types;
    },
    departments() {
      return this.datasets.departments;
    },
  },
  methods: {
    async getTicket() {
      this.isLoading = true;
      let id = Number(this.$route.params.id);
      let uid = this.user.uid;
      let args = {
        collectionName: "tickets",
        where: ["sender", "==", uid],
        where2: ["id", "==", id],
      };
      let result = await this.$db.getCollection(args);
      if (result) {
        this.ticket = result[0];
        this.getTicketEvets();
      }
      this.isLoading = false;
    },
    async getTicketEvets() {
      this.isLoading = true;
      let id = Number(this.$route.params.id);
      let args = {
        collectionName: "tickets_events",
        where: ["user", "==", this.user.uid],
        where2: ["ticket", "==", id],
        order: ["created_at", "desc"],
        limitBy: 48,
      };
      this.events = await this.$db.getCollection(args);
      this.isLoading = false;
    },
    getStatus(id) {
      var status = this.statuses.find((el) => el.id == id);
      return status.name;
    },
    getType(slug) {
      var type = slug;
      if (this.ticket_types) {
        var found = this.ticket_types.find((el) => el.slug.trim() == slug);
        if (found) {
          type = found.name;
        }
      }

      return type;
    },
    getDepartment(id) {
      var department = this.departments.find((el) => el.id == id);
      return department;
    },
  },
  mounted() {
    this.getTicket();
  },
};
</script>

<style lang="scss" scoped>
.single-ticket {
  h6 {
    font-size: 14px !important;
    color: #838b9d;
    margin-bottom: 5px;
  }
  .files {
    a {
      font-size: 13px;
    }
  }
}
</style>
