<template>
  <router-link
    to="/"
    class="logo d-flex align-items-center"
    :class="{ dark: dark }"
  >
    <img src="@/assets/logo.png" alt="" />
    <div class="logo__text">
      <strong class="d-block">Дрогобич Smart City</strong>
      <span>
        Кабінет жителя
      </span>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ["dark"],
};
</script>

<style lang="scss" scoped>
.logo {
  text-decoration: none;
  &.dark {
    strong,
    span {
      color: #000;
    }
    .logo__text {
      border-left: 1px solid #000;
      padding-left: 14px;
      margin-left: 14px;
    }
  }
  &__text {
    padding-left: 14px;
    line-height: 1.3;
    strong {
      font-weight: bold;
      font-size: 18px;
      color: #ffffff;
    }
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #ffffff;
      opacity: 0.6;
    }
  }
  img {
    height: 48px;
  }
}
</style>
