<template>
  <div class="service">
    <div class="back-button">
      <router-link :to="{ name: 'services' }">
        <icon
          variant="arrow-right"
          color="#3683fb"
          style="transform:scaleX(-1)"
        />
        Всі сервіси
      </router-link>
    </div>
    <b-card>
      <div
        v-if="isLoading"
        class="d-flex align-items-center justify-content-center"
        style="height:400px"
      >
        <b-spinner variant="warning" size="large"></b-spinner>
      </div>
      <div v-else>
        <service-external
          v-if="service_type == 'external'"
          :service="service"
        />
        <service-local   v-if="service_type == 'local'" :service="service" />
      </div>
    </b-card>
  </div>
</template>

<script>
import serviceExternal from "./service-single-components/service-external.vue";
import serviceLocal from "./service-single-components/service-local.vue";
export default {
  components: { serviceExternal, serviceLocal },
  data() {
    return {
      isLoading: false,
      error: null,
      service_type: "local",
      service: {},
    };
  },
  watch:{
    $route:{
      handler(){
        this.getLocalSerivce();
      }
    }
  },
  methods: {
    async getLocalSerivce() {
      this.isLoading = true;
      var conditions = ["slug", "==", this.$route.params.slug];
      var result = await this.$db.getCollectionWhere(
        "local_services",
        conditions
      );
      this.service = result[0];
      this.isLoading = false;
    },
    getExternalService() {
      this.isLoading = true;
      var id = this.$route.params.id;
      fetch(
        "https://drohobych-rada.gov.ua/wp-json/wp/v2/smart-service?include[]=" +
          id
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.service = data[0];
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error;
          this.isLoading = false;
        });
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.service_type = "external";
      this.getExternalService();
    } else if (this.$route.params.slug) {
      this.getLocalSerivce();
    } else {
      this.$router.push("services");
    }
  },
};
</script>

<style lang="scss" scoped></style>
