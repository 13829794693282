const getters = {
  isPhoneVerified(state) {
    let verified = false;
    if (state.userData.phoneVerified == true) {
      verified = true;
    }
    return verified;
  },
  sideBarOpened(state) {
    return state.sideBarOpened;
  },
  getUser(state) {
    return state.user;
  },
  getUserData(state) {
    return state.userData;
  },
  isUser(state) {
    return !!state.user;
  },
  getError(state) {
    return state.error;
  },
  getDatasets(state) {
    return state.datasets;
  },
  getNotificationsCount(state) {
    return state.notificationsCount;
  },
};

export default getters;
