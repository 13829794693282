<template>
  <div class="services">
    <h1>
      Сервіси і послуги
    </h1>
    <p>
      Перелік сервісів і послуг, якими можна скористатися онлайн
    </p>
    <b-form-input
      v-model="searchTerm"
      placeholder="Почніть вводити назву або ключове слово послуги"
      class="mb-4 filter-services"
    />
    <div
      v-if="isLoading"
      class="d-flex align-items-center justify-content-center"
      style="height:400px"
    >
      <b-spinner variant="warning" size="large"></b-spinner>
    </div>
    <div v-else>
      <div class="services__list">
        <local-service-card
          :searchTerm="searchTerm"
          v-for="s in local_services_filtered"
          :key="s.slug"
          :s="s"
        />
        <remote-service-card
          :searchTerm="searchTerm"
          v-for="s in remote_services_filtered"
          :key="s.id"
          :s="s"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RemoteServiceCard from "./services-components/remote-service-card.vue";
import LocalServiceCard from "./services-components/local-service-card.vue";
export default {
  components: {
    RemoteServiceCard,
    LocalServiceCard,
  },
  data() {
    return {
      isLoading: false,
      searchTerm: "",
      remote_services: [],
      local_services: [],
    };
  },
  computed: {
    local_services_filtered() {
      let services = this.local_services;
      if (this.searchTerm.length) {
        services = this.local_services.filter(
          (item) =>
            item.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >
              -1 ||
            (item.description &&
              item.description
                .toLowerCase()
                .indexOf(this.searchTerm.toLowerCase()) > -1)
        );
      }

      return services;
    },
    remote_services_filtered() {
      let services = this.remote_services;
      if (this.searchTerm.length) {
        services = this.remote_services.filter(
          (item) =>
            item.title.rendered
              .toLowerCase()
              .indexOf(this.searchTerm.toLowerCase()) > -1 ||
            (item.acf.service_descr &&
              item.acf.service_descr
                .toLowerCase()
                .indexOf(this.searchTerm.toLowerCase()) > -1)
        );
      }

      return services;
    },
  },
  methods: {
    async getLocalServices() {
      let args = {
        collectionName: "local_services",
      };
      var result = await this.$db.getCollection(args);
      for (var key in result) {
        this.local_services.push(result[key]);
      }
    },

    getRemoteServices() {
      this.isLoading = true;
      fetch(
        "https://drohobych-rada.gov.ua/wp-json/wp/v2/smart-service?page=1&filter[posts_per_page]=100"
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.remote_services = data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.getRemoteServices();
    this.getLocalServices();
  },
};
</script>

<style lang="scss" scoped>
.services {
  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 16px;
    row-gap: 8px;
    @media (max-width: 1280px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (max-width: 1024px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 475px) {
      grid-template-columns: 1fr;
    }
  }
}
</style>
