<template>
  <div>
    <h1>
      Мої звернення і запити
    </h1>
    <p>
      Тут відображатимуться всі звернення та запити, надіслані Вами
    </p>
    <b-overlay
      :show="isLoading"
      :class="{ 'overlay-loading': isLoading }"
      variant="white"
      rounded="sm"
      spinner-variant="warning"
    >
      <template v-if="tickets.length">
        <ticketListItem v-for="t in tickets" :t="t" :key="t.id" />
      </template>
      <template v-if="!tickets.length && !isLoading">
        <b-alert show variant="primary">
          <h6 class="mb-0">
            Немає звернень
          </h6>
        </b-alert>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ticketListItem from "@/components/ticket-list-item.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    ticketListItem,
  },
  data() {
    return {
      isLoading: false,
      tickets: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
  methods: {
    async getTickets() {
      this.isLoading = true;
      let args = {
        collectionName: "tickets",
        where: ["sender", "==", this.user.uid],
        order: ["created_at", "desc"],
        limitBy: 20,
      };
      console.log(args);
      try {
        var result = await this.$db.getCollection(args);
        this.tickets = result;
        this.isLoading = false;
      } catch {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getTickets();
  },
};
</script>

<style lang="scss" scoped></style>
