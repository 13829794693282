<template>
  <div class="form-group">
    <label class="mb-3 mt-4">
      Оберіть до якого адресуєте звернення
      <span class="required">*</span>
    </label>
    <v-select
      :disabled="disableSelect"
      id="department-parent"
      v-model="selected"
      @input="emitSelect"
      :options="departmentOptions"
      :reduce="(text) => text.value"
      label="text"
      placeholder="Оберіть посадовця"
    >
      <!-- eslint-disable-next-line vue/no-unused-vars  -->
      <template #no-options="{ search, searching, loading }">
        Нічого не знайдено
      </template>
      <template #option="{ text, parent }">
        <div class="position">{{ text }}</div>
        <span class="department">
          {{ getDepartment(parent) }}
        </span>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import _ from "lodash";
export default {
  components: {
    vSelect,
  },
  data() {
    return { selected: null, hasFewDepartments: false, disableSelect: false };
  },
  props: ["department"],
  computed: {
    ...mapGetters({
      user: "getUser",
      isUser: "isUser",
      isVerified: "isPhoneVerified",
      datasets: "getDatasets",
    }),
    hasDepartment() {
      let has = false;
      // if (this.department !== null) {
      //   has = true;
      // }
      return has;
    },
    all_departments() {
      return this.datasets.departments;
    },
    departmentOptions() {
      let list = this.all_departments.filter((e) => {
        return e.is_position;
      });
      list = list.map((e) => {
        let item = {
          text: e.name,
          value: e.id,
          parent: e.parent,
          sorting: e.sorting,
        };
        if (e.current_worker_name) {
          item["workers_name"] = e.current_worker_name;
          item.text = item.text + " (" + e.current_worker_name + ")";
        }
        return item;
      });
      if (this.department && this.department.length) {
        list = list.filter((el) => {
          return this.department.indexOf(el.value) > -1;
        });
      }
      list = _.orderBy(list, ["sorting"], ["asc"]);
      return list;
    },
  },
  methods: {
    emitSelect(value) {
      this.$emit("select", value);
    },
    emitSelectSingle() {
      this.$emit("select", this.selected);
    },
    getDepartment(id) {
      let result = this.all_departments.find((e) => {
        return e.id == id;
      });
      return result.name;
    },
  },
  mounted() {
    setTimeout(() => {
      let options = this.departmentOptions;
      if (options.length == 1) {
        this.selected = options[0].value;
        this.disableSelect = true;
        this.emitSelectSingle();
      }
    }, 600);
  },
};
</script>

<style lang="scss" scoped>
.department {
  color: #999;
  font-size: 13px;
  margin-bottom: 10px;
  display: block;
}
.position {
  font-size: 16px;
  color: #000;
  font-weight: 600;
}
.vs__dropdown-option--highlight * {
  color: #fff !important;
}
</style>
