var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group"},[_vm._m(0),_c('v-select',{attrs:{"disabled":_vm.disableSelect,"id":"department-parent","options":_vm.departmentOptions,"reduce":function (text) { return text.value; },"label":"text","placeholder":"Оберіть посадовця"},on:{"input":_vm.emitSelect},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Нічого не знайдено ")]}},{key:"option",fn:function(ref){
var text = ref.text;
var parent = ref.parent;
return [_c('div',{staticClass:"position"},[_vm._v(_vm._s(text))]),_c('span',{staticClass:"department"},[_vm._v(" "+_vm._s(_vm.getDepartment(parent))+" ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"mb-3 mt-4"},[_vm._v(" Оберіть до якого адресуєте звернення "),_c('span',{staticClass:"required"},[_vm._v("*")])])}]

export { render, staticRenderFns }