import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCsvxB_NdcAEeYRvqWLx0OebjTpgRgGA5Q",
  authDomain: "citizen-drohobych.firebaseapp.com",
  projectId: "citizen-drohobych",
  storageBucket: "citizen-drohobych.appspot.com",
  messagingSenderId: "95979744128",
  appId: "1:95979744128:web:70ffc757baeb36e2a7ef65",
  measurementId: "G-ZP93FR9E2W",
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
//Firebase Auth services
import auth from "./providers/auth";
Vue.prototype.$auth = auth(app);

//Firebase Auth services
import db from "./providers/db";
Vue.prototype.$db = db(app);

//AXIOS as API
import api from "./providers/api";
Vue.prototype.$api = api.axiosApi;
Vue.prototype.$fileApi = api.fileApi;

import { BootstrapVue } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/scss/bootstrap-overrides.scss";
Vue.use(BootstrapVue);

//TOastification
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const toastOptions = {
  timeout: 3000,
};
Vue.use(Toast, toastOptions);

//Vue ripple
import Ripple from "vue-ripple-directive";
Ripple.color = "rgba(255, 255, 255, 0.20)";
Ripple.zIndex = 55;
Vue.directive("ripple", Ripple);

//Moment.js [date formatting]
const moment = require("moment");
require("moment/locale/uk");

Vue.use(require("vue-moment"), {
  moment,
});

//Icons utility
import icon from "@/layout/icons/icon-component";
Vue.component("icon", icon);

Vue.config.productionTip = false;

new Vue({
  app,
  db,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
