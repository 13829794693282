<template>
  <div class="auth">
    <div class="blue"></div>
    <div class="container">
      <div class="row align-items-center">
        <div
          class="col-12 col-md-5 col-lg-6 auth__column info order-2 order-md-1"
        >
          <div class="auth__how-it-works">
            <h3>
              Як це працює?
            </h3>
            <p>
              Особистий кабінет дає можливість:
            </p>
            <ul>
              <li>
                надсилати запити до влади міста та отримувати офіційні відповіді
              </li>
              <li>
                здійснювати запис на прийоми до посадовців
              </li>
              <li>
                повідомити про корупцію, тощо
              </li>
            </ul>
          </div>
        </div>
        <div
          class="col-12 col-md-7 col-lg-6 auth__column order-1 order-md-2"
          style="margin-bottom: auto;padding-top:10vh"
        >
          <div class="auth__padding">
            <div class="auth__logo">
              <logo :dark="true" />
            </div>
            <template v-if="!emailSent">
              <div class="back-button">
                <router-link :to="{ path: '/auth/login' }">
                  <icon
                    variant="arrow-right"
                    color="#3683fb"
                    style="transform:scaleX(-1)"
                  />
                  Назад
                </router-link>
              </div>
              <h4>
                Скидання паролю
              </h4>
              <p>
                Введіть Email, який було використано для створення аккаунту
              </p>
              <b-overlay
                :show="isLoading"
                variant="white"
                rounded="sm"
                spinner-variant="warning"
              >
                <div class="form-group">
                  <input
                    v-model="email"
                    type="email"
                    :disabled="isLoading"
                    placeholder="Email"
                    class="w-100 mb-2"
                  />
                  <b-button
                    :disabled="isLoading || !emailValid"
                    @click="sendResetEmail"
                    variant="primary w-100"
                  >
                    Скинути пароль
                  </b-button>
                </div>
              </b-overlay>
            </template>
            <template v-if="emailSent">
              <h4>
                Скидання паролю
              </h4>
              <b-alert class="mt-2" show variant="success">
                Ми надіслали інструкції для відновлення паролю на Ваш email
                <strong>{{ email }}</strong>
              </b-alert>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Logo from "@/layout/logo.vue";
export default {
  components: {
    Logo,
  },
  data() {
    return {
      isLoading: false,
      email: "",
      emailSent: false,
      result: "",
    };
  },
  computed: {
    ...mapGetters(["isUser"]),
    emailValid() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.email).toLowerCase());
    },
  },
  methods: {
    async sendResetEmail() {
      this.isLoading = true;
      var url = "https://smart.drohobych-rada.gov.ua/forgot-password/confirm";
      var result = await this.$auth.passwordReset(this.email, { url: url });
      if (result && result.indexOf("auth/user-not-found")) {
        this.$toast.error("Користувача не знайдено");
      } else if (result && result.toLowerCase().indexOf("error")) {
        this.$toast.error("Сталася помилка");
      } else {
        this.emailSent = true;
        this.$toast.success("Лист з інструкцією надіслано");
      }

      this.isLoading = false;
      this.result = result;
    },
  },
  mounted() {
    this.$bvModal.hide("auth-modal");
  },
  beforeMount() {
    if (this.isUser) {
      this.$router.push({ name: "Home" });
    }
  },
};
</script>

<style lang="scss" scoped>
.auth {
  position: relative;
  min-height: 100vh;
  max-height: 100%;
  height: 100%;
  .container {
    @media (max-width: 991px) {
      max-width: 100%;
    }
  }
  &__column {
    max-height: 100vh;
    overflow: scroll;
    @media (max-width: 768px) {
      padding-top: 60px !important;

      &.info {
        background: #1f59af;
        padding-bottom: 80px;
      }
    }
  }
  &__padding {
    padding: 30px 60px;
    background: #fff;
    border-radius: 6px;
    @media (max-width: 575px) {
      padding: 20px 20px 60px;
    }
  }
  &__logo {
    margin-bottom: 56px;
  }
  &__how-it-works {
    color: #fff;
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
    }
    p {
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 1.5;
      color: #ffffff;
    }
  }
  .blue {
    background: #1f59af;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    z-index: 1;
    @media (min-width: 768px) and (max-width: 991px) {
      width: 41.66666667%;
    }
    @media (max-width: 768px) {
      position: relative;
    }
  }
  .container {
    position: relative;
    z-index: 3;
    .row {
      min-height: 100vh;
      max-height: 100vh;
    }
  }
}
</style>
