<template>
  <div class="sidebar" :class="{ opened: sidebarOpened }">
    <div class="sidebar-bg">
      <div class="sidebar__header">
        <logo />
      </div>
      <div class="sidebar__main">
        <ul>
          <li>
            <router-link :to="{ name: 'services' }">
              <icon variant="grid" color="#fff" />
              <span>Каталог сервісів</span>
            </router-link>
          </li>

          <li>
            <router-link :to="{ name: 'tickets' }" v-if="isUser">
              <icon variant="papers" color="#fff" />
              <span>Мої звернення і запити</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'appointments' }" v-if="isUser">
              <icon variant="calendar" color="#fff" />
              <span>Мої записи на прийом</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'notifications' }" v-if="isUser">
              <span class="count" v-if="notificaions > 0">{{
                notificaions
              }}</span>
              <icon variant="bell" color="#fff" />
              <span>Сповіщення </span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'my' }" v-if="isUser">
              <icon variant="user" :height="24" :width="24" color="#fff" />
              <span>Мій профіль</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="sidebar__bottom"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import logo from "@/layout/logo.vue";
export default {
  components: {
    logo,
  },
  computed: {
    ...mapGetters({
      isUser: "isUser",
      sidebarOpened: "sideBarOpened",
      notificaions: "getNotificationsCount",
    }),
  },
  methods: {
    closeSidebar() {
      this.$store.dispatch("toggleSidebar", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  min-height: 100vh;
  max-height: 100vh;
  width: var(--sidebar-width);

  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;

  transition: 0.3s;
  z-index: 9999;
  @media (max-width: 991px) {
    transform: translateX(-100%);
  }

  & > * {
    position: relative;
    z-index: 3;
  }

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    @media (min-width: 768px) {
      right: -22px;
    }
    background: #1f59af;
    z-index: 1;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-right: 10px;
  }
  &.opened {
    transform: translateX(0%);
    transition: 0.3s;
  }
  &__header {
    height: 72px;
    padding: 16px 18px;
    margin-bottom: 60px;
  }
  &__bottom {
    height: 100px;
  }
  &__main {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        a {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          color: #ffffff;
          display: flex;
          height: 64px;
          align-items: center;
          box-sizing: border-box;
          padding: 5px 20px;
          text-decoration: none;
          box-sizing: border-box;
          border-left: 3px solid transparent;
          transition: 0.3s;
          position: relative;
          .count {
            color: #fff;
            display: inline-flex;
            width: 20px;
            height: 20px;
            font-size: 13px;
            background: #f00;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            position: absolute;
            top: 10px;
            left: 10px;
          }
          &.router-link-exact-active,
          &.router-link-active {
            background: rgba(#fff, 0.1);
            border-left: 3px solid #fff;
            transition: 0.3s;
            svg {
              opacity: 1;
            }
          }
          &:hover {
            svg {
              opacity: 1;
              transition: 0.3s;
            }
          }
          svg {
            width: 24px;
            height: 24px;
            margin-right: 16px;
            opacity: 0.6;
            transition: 0.3s;
          }
        }
      }
    }
  }
}
</style>
