<template>
  <div class="auth">
    <div class="blue"></div>
    <div class="container">
      <div class="row align-items-center">
        <div
          class="col-12 col-md-5 col-lg-6 auth__column info order-2 order-md-1"
        >
          <div class="auth__how-it-works">
            <h3>
              Як це працює?
            </h3>
            <p>
              Особистий кабінет дає можливість:
            </p>
            <ul>
              <li>
                надсилати запити до влади міста та отримувати офіційні відповіді
              </li>
              <li>
                здійснювати запис на прийоми до посадовців
              </li>
              <li>
                повідомити про корупцію, тощо
              </li>
            </ul>
          </div>
        </div>
        <div
          class="col-12 col-md-7 col-lg-6 auth__column order-1 order-md-2"
          style="margin-bottom: auto;padding-top:10vh"
        >
          <div class="auth__padding">
            <div class="auth__logo">
              <logo :dark="true" />
            </div>
            <h4>
              Створення новго паролю
            </h4>
            <p>
              Створіть новий пароль
            </p>
            <b-overlay
              :show="isLoading"
              variant="white"
              rounded="sm"
              spinner-variant="warning"
            >
              <div class="form-group">
                <label for="">
                  Пароль
                </label>
                <input
                  :disabled="isLoading"
                  v-model="password"
                  type="password"
                  placeholder="Новий пароль"
                  class="w-100 mb-2"
                />
              </div>
              <div class="form-group">
                <label for="">
                  Пароль повторно
                </label>
                <input
                  :disabled="isLoading"
                  v-model="password_confirm"
                  type="password"
                  placeholder="Новий пароль повторно"
                  class="w-100 mb-2"
                />
              </div>
            </b-overlay>
            <b-button
              :disabled="buttonDisabled"
              @click="savePassword"
              variant="primary w-100"
            >
              Зберегти пароль
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Logo from "@/layout/logo.vue";
export default {
  components: {
    Logo,
  },
  data() {
    return {
      isLoading: false,
      password: "",
      password_confirm: "",
      code: "",
      result: "",
    };
  },
  computed: {
    ...mapGetters(["isUser"]),
    buttonDisabled() {
      let disabled = false;
      if (this.isLoading) {
        disabled = true;
      }
      if (this.password !== this.password_confirm && this.password.length < 6) {
        disabled = true;
      }
      return disabled;
    },
  },
  methods: {
    async savePassword() {
      this.isLoading = true;
      let code = this.code;
      var result = await this.$auth.newPasswordConfirm(code, this.password);
      console.log(result);
      if (result && result.message.indexOf("error")) {
        this.$toast.error("Сталась помилка");
      } else {
        this.$toast.success("Пароль збережено");
        this.$router.push({ path: "/auth/login" });
      }
      this.isLoading = false;
    },
  },
  beforeMount() {
    if (this.isUser) {
      this.$router.push({ name: "Home" });
    }
  },
  mounted() {
    this.code = this.$route.query.oobCode;
  },
};
</script>

<style lang="scss" scoped>
.auth {
  position: relative;
  min-height: 100vh;
  max-height: 100%;
  height: 100%;
  .container {
    @media (max-width: 991px) {
      max-width: 100%;
    }
  }
  &__column {
    max-height: 100vh;
    overflow: scroll;
    @media (max-width: 768px) {
      padding-top: 60px !important;

      &.info {
        background: #1f59af;
        padding-bottom: 80px;
      }
    }
  }
  &__padding {
    padding: 30px 60px;
    background: #fff;
    border-radius: 6px;
    @media (max-width: 575px) {
      padding: 20px 20px 60px;
    }
  }
  &__logo {
    margin-bottom: 56px;
  }
  &__how-it-works {
    color: #fff;
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
    }
    p {
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 1.5;
      color: #ffffff;
    }
  }
  .blue {
    background: #1f59af;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    z-index: 1;
    @media (min-width: 768px) and (max-width: 991px) {
      width: 41.66666667%;
    }
    @media (max-width: 768px) {
      position: relative;
    }
  }
  .container {
    position: relative;
    z-index: 3;
    .row {
      min-height: 100vh;
      max-height: 100vh;
    }
  }
}
</style>
