import axios from "axios";

const token = localStorage.getItem("token");
let headers = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
};

if (token) {
  headers["Authorization"] = `Bearer ${token}`;
}

const axiosApi = axios.create({
  //Base URL for API calls
  baseURL: "https://us-central1-citizen-drohobych.cloudfunctions.net/api",
  // baseURL: "http://localhost:5001/citizen-drohobych/us-central1/api",
  // withCredentials: true,
  headers: headers,
});

const fileApi = axios.create({
  //Base URL for API calls
  baseURL: "https://project.realno.top/api/file/save/",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});
let api = {
  axiosApi: axiosApi,
  fileApi: fileApi,
};

export default api;
