<template>
  <b-card class="mb-3">
    <div class="n__header d-flex align-items-center mb-2">
      <div class="n__status-change" v-if="event.next_status">
        Зміна статусу:
        <statusBadge :status="event.prev_status" />
        <icon style="margin: 0 10px" variant="arrow-right" />
        <statusBadge :status="event.next_status" />
        <span style="margin-left: 5px" v-if="event.next_department">
          до
          <strong>
            {{ getDepartment(event.next_department).name }}
          </strong>
        </span>
      </div>
    </div>
    <div class="n__type">
      <div class="response mt-1">
        <div class="response-text">
          <h6 class="mb-0">
            <strong> Відповідь: </strong>
          </h6>
          {{ event.response_text }}
          <div class="response-files mt-1" v-if="event.files">
            <strong> Файли </strong>
            <p v-if="event.files.length < 1">Немає файлів</p>
            <div v-for="f in event.files" :key="f.id">
              <a :href="f.file_url" target="_blank">
                {{ f.orig_path }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="n__footer text-right d-flex align-items-center">
      <div class="n__date">
        {{ event.created_at.seconds | moment("calendar") }}
      </div>
      <div class="n__worker" v-if="worker">
        від
        <strong> {{ worker.last_name }} {{ worker.first_name }} </strong>
        ({{ getDepartment(worker.department).name }})
      </div>
    </div>
  </b-card>
</template>

<script>
import statusBadge from "@/components/ticket-status-badge.vue";
import { mapGetters } from "vuex";
export default {
  props: ["event"],
  components: {
    statusBadge,
  },
  data() {
    return {
      worker: null,
    };
  },
  computed: {
    ...mapGetters({
      datasets: "getDatasets",
    }),
    ticket_types() {
      return this.datasets.ticket_types;
    },
    departments() {
      return this.datasets.departments;
    },
  },
  methods: {
    async getWorkerInfo() {
      let uid = this.event.sender;
      let worker = await this.$db.getDocument("users", uid);
      this.worker = worker;
    },
    markAsRead(id) {
      console.log(id);
    },
    getType(slug) {
      var type = slug;
      if (this.ticket_types) {
        var found = this.ticket_types.find((el) => el.slug == slug);
        if (found) {
          type = found.name;
        }
      }

      return type;
    },
    getDepartment(id) {
      var department = this.departments.find((el) => el.id == id);
      return department;
    },
  },
  mounted() {
    this.getWorkerInfo();
  },
};
</script>

<style lang="scss" scoped>
.n {
  &__header {
    font-size: 13px;
    color: #666;
    strong {
      color: #424242;
    }
    .status-badge {
      padding: 4px 6px;
      border-radius: 3px;
    }
  }
  &__type {
    padding-top: 5px;
    font-size: 18px;
    a {
      text-decoration: none;
    }
    .response-text {
      padding: 8px;
      background: #fdf8e9;
      border-bottom: 2px solid darken(#fdf8e9, 5%);
      border-right: 2px solid darken(#fdf8e9, 5%);
      .response-files {
        padding-top: 20px;
        a {
          font-size: 13px;
        }
      }
    }
  }
  &__worker {
    margin-left: 10px;
    strong {
      color: #000;
      margin-left: 3px;
    }
  }
  &__footer {
    text-align: right;
    border-top: 1px solid #e7e7e7;
    padding-top: 10px;
    margin-top: 10px;
    font-size: 13px;
    a {
      margin-left: 12px;
      font-size: 13px;
    }
  }
}
</style>
