<template>
  <div>
    <div
      class="
        service__head
        flex-column flex-sm-row
        align-items-baseline
        d-flex
        align-items-sm-center
      "
    >
      <div
        class="service__thumb"
        :style="{ backgroundImage: 'url(' + service.image_url + ')' }"
      ></div>
      <div class="d-flex flex-column">
        <h1 class="service__title">
          {{ service.name }}
        </h1>
        <p class="service__descr">{{ service.description }}</p>
      </div>
    </div>
    <div class="service__body">
      <b-tabs content-class="mt-3" class="mt-4" pills>
        <b-tab title="Сервіс" active>
          <template v-if="service.slug == 'anonymous-corruption-report'">
            <anonymous-corruption-report :s="service" />
          </template>
          <template v-if="service.slug == 'information_request'">
            <information-request :s="service" />
          </template>
          <template v-if="service.slug == 'appeals'">
            <appeals :s="service" />
          </template>
          <template v-if="service.slug == 'dwelling-queue-application'">
            <dwellingQueueApplication :s="service" />
          </template>
          <template v-if="service.slug == 'dwelling-queue-information'">
            <dwellingQueueInformation :s="service" />
          </template>
          <template v-if="service.slug == 'schedule-an-appointment'">
            <scheduleAnAppointment :s="service" />
          </template>
          <template v-if="service.slug == 'social-help-request'">
            <socialHelpReuqest :s="service" />
          </template>
        </b-tab>
        <b-tab title="Інформація" v-if="service.about">
          <div v-html="service.about"></div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
//services content
import anonymousCorruptionReport from "@/components/local-services/anonymous-corruption-report.vue";
import informationRequest from "@/components/local-services/information-request.vue";
import appeals from "@/components/local-services/send-appeal-to-government.vue";
import dwellingQueueApplication from "@/components/local-services/dwelling-queue-application.vue";
import dwellingQueueInformation from "@/components/local-services/dwelling-queue-info.vue";
import scheduleAnAppointment from "@/components/local-services/schedule-an-appointment.vue";
import socialHelpReuqest from "@/components/local-services/social-help-request.vue";

export default {
  components: {
    anonymousCorruptionReport,
    dwellingQueueApplication,
    dwellingQueueInformation,
    informationRequest,
    scheduleAnAppointment,
    socialHelpReuqest,
    appeals,
  },
  props: ["service"],
  service_tabs: ["info", "faq", "howto"],
};
</script>

<style lang="scss" scoped>
.service {
  &__external-link {
    padding: 12px;
    background: #f4f4f4;
    border-radius: 8px;
  }
  &__title {
    font-size: 32px;
    @media (max-width: 575px) {
      font-size: 24px;
      margin-top: 20px;
    }
  }
  &__descr {
    font-size: 15px;
    color: #424242;
  }
  &__thumb {
    width: 180px;
    min-width: 180px;
    height: 120px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.2);
    margin-right: 30px;
    background-color: #e7e7e7;
    border: 2px solid #fff;
  }
}
</style>
