<template>
  <div>
    <router-link
      :to="{
        name: 'service-single-local',
        params: { slug: s.slug },
        props: { remote: true },
      }"
      data-external="false"
      :data-url="s.name"
      data-type="citizen"
      class="services__item"
      ><div
        class="image"
        :style="{ backgroundImage: 'url(' + s.image_url + ')' }"
      ></div>
      <div class="info">
        <div class="name" v-html="highlightTerms(s.name)"></div>
        <div class="descr">
          <span v-if="s.description">
            {{ s.description }}
          </span>
        </div>
      </div>
      <div class="new-badge" v-if="s.is_new">Нове</div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ["s", "searchTerm"],
  methods: {
    highlightTerms(value) {
      value = value.toString();
      let term = this.searchTerm.toString();
      if (term.length > 1) {
        var reg = new RegExp(term, "gi");
        value = value.replace(reg, function (str) {
          return '<strong class="search-excerpt">' + str + "</strong>";
        });
        return value;
      } else {
        return value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.services {
  &__item {
    display: flex !important;
    flex-direction: column;
    padding: 15px;
    background: #ffffff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    margin-bottom: 20px;
    transition: 0.3s;
    cursor: pointer;
    text-decoration: none;
    min-height: 200px;
    position: relative;
    overflow: hidden;
    .new-badge {
      padding: 6px 10px;
      background: #f00;
      position: absolute;
      z-index: 20;
      color: #fff;
      font-size: 14px;
      right: 0;
      top: 10px;
      box-shadow: 0px 2px 10px 0px rgba(#f00, 0.3);
    }
    @media (max-width: 768px) {
      padding: 0px;
    }

    .info {
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      padding: 0px 10px;
      background-color: #fff;

      @media (max-width: 768px) {
        position: relative;
        top: initial;
        left: initial;
        bottom: initial;
      }
    }

    &:hover {
      .descr {
        max-height: 300px;
        overflow: hidden;
        transition: 0.6s;
      }
    }

    &:hover {
      box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.25);
      transition: 0.3s;
      text-decoration: none;
    }

    .image {
      position: absolute;
      left: 0px;
      top: 0px;
      right: 0px;
      bottom: 30px;
      background-size: cover;
      background-position: center;
      background-color: #ccc;
      @media (max-width: 768px) {
        position: relative;
        top: initial;
        left: initial;
        bottom: initial;
        right: initial;
        padding-top: 75%;
      }
      @media (max-width: 575px) {
        padding-top: 50%;
      }
    }

    .name {
      font-style: normal;
      font-weight: 800;
      font-size: 15px;
      color: #000000;
      padding-top: 10px;
      padding-bottom: 15px;
      line-height: 1.2;
      .search-excerpt {
        background: #f6ff00;
        display: inline-block;
      }
    }

    .descr {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #898989;
      max-height: 0px;
      overflow: hidden;
      transition: 0.3s;

      span {
        padding-bottom: 15px;
        display: block;
      }

      @media (max-width: 600px) {
        font-size: 16px;
      }
      @media (max-width: 768px) {
        max-height: 1000px;
      }
    }

    .link {
      color: #104d82;
      font-weight: bold;
    }
  }
}
</style>
